import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

export const CheckExistLayerName = (name) => {
  return service
    .get(`${ApiUrl.Check_Exist_Layer_Name}?name=${name}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};