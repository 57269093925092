const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  // DOMAIN SERVER MỚI
  development: {
    api: "https://qhctpbienhoa-api.cgis.asia/",
    media_url: "https://qhctpbienhoa-api.cgis.asia/",
    domainAdminSide: "http://localhost:3000",
    domainUserSide: "http://localhost:3000",
    domainName: "",
    workSpace: "dongnai_bienhoa",
    wmsBaseLink: "https://geoserver.phutho.gov.vn/geoserver/dongnai_bienhoa/wms/",
    datastore: 'dongnai_bienhoa',
    gwcUrl: 'https://geoserver.phutho.gov.vn/geoserver/gwc/service/wms/'
    // wmsBaseLink: "https://geoserver.phutho.gov.vn/geoserver/gwc/service/wms/",
  },
    production: {
      api: "https://qhctpbienhoa-api.cgis.asia/",
      media_url: "https://qhctpbienhoa-api.cgis.asia/",
      domainAdminSide: "https://qhcthanhphobienhoa-admin.cgis.asia",
      domainUserSide: "https://qhcthanhphobienhoa.cgis.asia",
      domainName: "cgis.asia",
      workSpace: "dongnai_bienhoa",
      wmsBaseLink: "https://geoserver.phutho.gov.vn/geoserver/gwc/service/wms/",
      datastore: 'dongnai_bienhoa',
      gwcUrl: 'https://geoserver.phutho.gov.vn/geoserver/gwc/service/wms/'
      // wmsBaseLink: "https://geo.cgis.asia/geoserver/gwc/service/wms/",
  }
};

module.exports = apiEnvironment[env];