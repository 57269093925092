import React, { useEffect, useState } from "react";
import * as apiLayerStore from "../../../../../redux/store/layer-style/layer-style.store";
import ShowNotification from "../../../../../components/react-notifications/react-notifications";
import * as viVN from "../../../../../language/vi-VN.json";
import { NotificationMessageType } from '../../../../../utils/configuration';
import { Button } from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as appActions from "../../../../../core/app.store";
import AddStyleModalView from "./modal-add-style/modal-add-style";
import RedirectToTiffModal from "./redirect-to-tiff-modal/redirect-to-tiff-modal";
import redirectToTiffModal from "./redirect-to-tiff-modal/redirect-to-tiff-modal";

const LayerStyle = (props) => {
  const { styleName, setStyleName, setIsEditStyle, showLoading } = props;

  const [openAddStyle, setOpenAddStyle] = useState(false);
  const [openRedirectToTiff, setOpenRedirectToTiff] = useState(false);

  const [listStyle, setListStyle] = useState([]);

  useEffect(() => {
    callGetListStyle();
  },[])
  
  const callGetListStyle = async () => {
    showLoading(true);
    return await new Promise((resolve, reject) => {
        apiLayerStore.GetListStyle().then(
            (res) => {
                resolve(res);
            },
            (err) => {
                reject(err);
            }
        );
    }).then((res) => {
        if (res && res.content && res.content.data) {
          setListStyle(res.content.data);
        }
        showLoading(false);
    })
    .catch((err) => {
      err &&
          err.errorType &&
          ShowNotification(
          viVN.Errors[err.errorType],
          NotificationMessageType.Error
      );
      showLoading(false);
    });
  };

  return (
    <div>
      <div className="form-group">
        <label className="h6 mb-3" for="selectExistingStyle">Chọn style có sẵn</label>
        <Autocomplete
          id="selectExistingStyle"
          blurOnSelect={true}
          inputValue={styleName}
          // value={styleName}
          defaultValue={styleName}
          onInputChange={(event, option) => {
            setStyleName(option ? (typeof option === "string" ? option : option.name) : "");
          }}
          onChange={(event, option) => {
            if(option && (typeof option === "string" ? option === 'no_style' : option.name === 'no_style')) {
              setOpenRedirectToTiff(true);
            }
            setStyleName(option ? (typeof option === "string" ? option : option.name) : "");
            setIsEditStyle();
          }}
          options={listStyle}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.name
          }
          renderInput={(params) => <TextField {...params} label="Chọn style có sẵn" variant="outlined" />}
        />
      </div>

      <div className="justify-content-end" style={{display: 'flex'}}>
        <Button variant="contained" color="primary" onClick={()=> {setOpenAddStyle(true)}}>
          Thêm mới style
        </Button>
      </div>

      <AddStyleModalView
        open={openAddStyle}
        handleClose={() => setOpenAddStyle(false)}
        listStyle={listStyle}
        callGetListStyle={callGetListStyle}
      />

      <RedirectToTiffModal 
        open={openRedirectToTiff}
        handleClose={() => {
          setOpenRedirectToTiff(false);
          props.jumpToChangeSourceTab();
          props.setIsJumpFromStyle(true);
        }}
      />
    </div>
  );
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => bindActionCreators({
  showLoading: appActions.ShowLoading,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(LayerStyle)