import React from 'react';
import TabPanel from '../child/TabPanel';
import { a11yProps } from '../child/tab-a11yProps';
import './modal-edit-layer.scss';
import GeneralInfomationView from '../general-infomation/general-infomation';
import EditLayerSettingView from '../setting-layer/setting-layer-edit';
import ChangeLayerDataSource from '../select-data-source/select-data-source-edit';
import LayerStyle from '../layer-style/layer-style';
import LayerInfomationViewEdit from '../layer-infomation-view/layer-infomation-view-edit';
import SettingLayerFilterForEditView from '../layer-filter-setting/layer-filter-setting-edit';
import ModalEditLayerModels from '../../../../../models/init-map-state/child/modal-edit-layer-models';
import LayerSettingsModels from "../../../../../models/map-data-model-b/layer-setting-models";
import SettingDescription from '../setting-layer/setting-description';
import {
    ConvertStandardDataDisplayProperyToControlDataSource,
    ConvertDisplayStandardPropertyToControlForm,
    MergeLayerDisplayStandardData,
    CreateUpdateLayerDisplayInfomationSettingObject,
    ConvertFilterStandardPropertyToControlForm,
    MergeFilterStandardData,
    CreateUpdateLayerFilterObject
} from '../../../config/config';
import ConfigModels from '../../../../../models/init-map-state/child/config-models';
import { Button, Tab, Tabs } from '@material-ui/core';
import * as apiLayerStore from "../../../../../redux/store/layer-style/layer-style.store";
import * as importTiffFileAction from "../../../../../redux/store/import-shape-tiff-file/import-tiff-file.store";
import * as checkExistLayerName from "../../../../../redux/store/import-shape-tiff-file/check-exist-layer-name.store";
import ShowNotification from "../../../../../components/react-notifications/react-notifications";
import * as viVN from "../../../../../language/vi-VN.json";
import { NotificationMessageType } from '../../../../../utils/configuration';
export default class ModalEditSettingLayer extends React.Component<ModalEditLayerModels.ModalEditLayerProps, ModalEditLayerModels.ModalEditLayerState> {
    constructor(props: ModalEditLayerModels.ModalEditLayerProps) {
        super(props)

        const Index = this.props?.layerRelaIndex;
        const LayerData = (this.props.isLayerRela && (this.props.layerRelaIndex || this.props.layerRelaIndex == 0)) ?
            this.props.layerData?.layerRealationships[this.props.layerRelaIndex]
            : this.props.layerData;
        const LayerParents = this.props.isLayerRela ? this.props.layerData : null;
        const LayerDisplay = LayerData.displayName;
        const LayerFilter = LayerData.filterName;
        const DisplayCols = LayerData.displayName.cols;
        const DataSource = ConvertStandardDataDisplayProperyToControlDataSource(LayerDisplay);
        const Year = LayerData?.year;
        const ContentChange = LayerData?.contentChange;
        const IsLayerRela = this.props?.isLayerRela;
        this.state = {
            layerData: LayerData,
            layerParents: LayerParents,
            value: 0,
            hasSave: false,
            dataSource: DataSource,
            viewDetail: ConvertDisplayStandardPropertyToControlForm(LayerDisplay.viewdetail.cols, DisplayCols),
            tooltip: ConvertDisplayStandardPropertyToControlForm(LayerDisplay.tooltip.cols, DisplayCols),
            popup: ConvertDisplayStandardPropertyToControlForm(LayerDisplay.popup.cols, DisplayCols),
            sortWidth: LayerData.filterName.order,
            inputSetting: ConvertFilterStandardPropertyToControlForm(LayerFilter.in, DataSource.cols),
            outputSetting: ConvertFilterStandardPropertyToControlForm(LayerFilter.out, DataSource.cols),
            styleName: "",
            isEditStyle: false,
            openConfirmModal: false,
            handleConfirmModalAccept: () => {},
            year: Year,
            contentChange: ContentChange,
            isLayerRela: IsLayerRela,
            layerRelaIndex: this.props.layerRelaIndex,
            valueTiffFile: null,
            isJumpFromStyle: false,
        }
    }

    componentDidMount() {
        console.log(this.state.viewDetail)
        console.log(this.state.inputSetting)
        console.log(this.props.isLayerRela);
        this.callGetStyleNameByLayerName(this.state.layerData.table);
        console.log(this.props);
    }

    handleChange = (event: any, newValue: number) => {
        this.setState({ value: newValue })
    };

    setIsJumpFromStyle = (val: boolean) => {
        this.setState({ isJumpFromStyle: val })
    }

    componentWillUnmount() {
        if(this.state.valueTiffFile) {
            this.validateTiffFileName(this.state.valueTiffFile)
            .then(passValidate => {
                if (!passValidate) {
                    this.setState({ hasSave: false });
                    return;
                }
                this.callImportTiffFile(this.state.valueTiffFile);
            })
            .catch(error => {
                console.error(error);
            });
        }

        if(this.state.isEditStyle) {
            this.callUpdateStyleForLayer(this.state.styleName, this.state.layerData.table);
        }
        
        if (this.state.hasSave && !this.state.isLayerRela) {
            this.props.setLayerData({
                ...this.state.layerData,
                displayName: MergeLayerDisplayStandardData(this.state.dataSource,
                    CreateUpdateLayerDisplayInfomationSettingObject(this.state.viewDetail, this.state.tooltip, this.state.popup)
                ),
                filterName: MergeFilterStandardData(CreateUpdateLayerFilterObject(this.state.inputSetting, this.state.outputSetting, this.state.sortWidth)),
                isChecked : this.state.layerData.is_check,
            });
        } else if(this.state.hasSave && this.state.isLayerRela && (this.state.layerRelaIndex || this.state.layerRelaIndex == 0)) {
            let data = this.state.layerParents.layerRealationships;
            data[this.state.layerRelaIndex] = {
                ...this.state.layerData,
                displayName: MergeLayerDisplayStandardData(this.state.dataSource,
                    CreateUpdateLayerDisplayInfomationSettingObject(this.state.viewDetail, this.state.tooltip, this.state.popup)
                ),
                filterName: MergeFilterStandardData(CreateUpdateLayerFilterObject(this.state.inputSetting, this.state.outputSetting, this.state.sortWidth))
            }
            this.props.setLayerData({ ...this.state.layerParents, layerRealationships: data});
        }
    }

    handleSave = () => {
        this.setState({ value: 0, hasSave: true });
        setTimeout(() => {
            this.props.closeModal();
        }, 10)
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if(prevState.layerData.table != this.state.layerData.table) {
            this.callGetStyleNameByLayerName(this.state.layerData.table);
        }
    }

    callGetStyleNameByLayerName = async (layerName: string) => {
        console.log("RUN WITH LAYERNAME: ",layerName);
        
        return await new Promise((resolve, reject) => {
            apiLayerStore.GetStyleNameByLayerName(layerName).then(
                (res) => {
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        }).then((res : any) => {
            if (res && res.content && res.content.data) {
                const splitArray = res.content.data.split(":");
                const styleNameSplitted = splitArray[splitArray.length - 1];
                this.setState({ styleName: styleNameSplitted })
            }  else {
                this.setState({ styleName: "" })
            }
        })
        .catch((err) => {
            err &&
                err.errorType &&
                ShowNotification(
                viVN.Errors[err.errorType as keyof typeof viVN.Errors],
                NotificationMessageType.Error
            );
        });
    };
    
    callUpdateStyleForLayer = async (styleName: string, tableName: string) => {
        return await new Promise((resolve, reject) => {
            apiLayerStore.UpdateStyleForLayer(styleName, tableName).then(
                (res) => {
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        }).then((res : any) => {
            console.log(res);
        })
        .catch((err) => {
            err &&
                err.errorType &&
                ShowNotification(
                viVN.Errors[err.errorType as keyof typeof viVN.Errors],
                NotificationMessageType.Error
            );
        });
    };

    callImportTiffFile = async (valueTiffFile: any) => {
        return await new Promise((resolve, reject) => {
            importTiffFileAction.ImportTiffFile(valueTiffFile).then(
                (res) => {
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        }).then((res : any) => {
            console.log(res);
        })
        .catch((err) => {
            err &&
                err.errorType &&
                ShowNotification(
                viVN.Errors[err.errorType as keyof typeof viVN.Errors],
                NotificationMessageType.Error
            );
        });
    };

    validateTiffFileName = (valueTiffFile: any) => {
        let validateResult = true;
    
        if (!valueTiffFile.TiffName) {
            ShowNotification(
                viVN.Errors.NameIsEmpty,
                NotificationMessageType.Error
            );
            validateResult = false;
            return Promise.resolve(validateResult);
        }
    
        return this.callCheckExistLayerName(valueTiffFile.TiffName)
            .then(checkExistResult => {
                if (checkExistResult) {
                    // true => trùng tên => ko cho đi tiếp
                    console.log('true => trùng tên => ko cho đi tiếp');
                    ShowNotification(
                        viVN.Errors.NameExisted,
                        NotificationMessageType.Error
                    );
                    validateResult = false;
                    return Promise.resolve(validateResult);
                }
    
                return Promise.resolve(validateResult);
            })
            .catch(error => {
                // Handle errors from callCheckExistLayerName or other async operations
                console.error(error);
                return Promise.reject(error);
            });
    };
    

    callCheckExistLayerName = (tiffName: any) => {
        return new Promise((resolve, reject) => {
            checkExistLayerName.CheckExistLayerName(tiffName).then(
                (res) => {
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        }).then((res: any) => {
            if (res && res.content && res.content.status == false) {
                console.log('return false;');
                return false;
            }
            console.log('return true 1;');
            return true;
        })
        .catch((err) => {
            err &&
                err.errorType &&
                ShowNotification(
                viVN.Errors[err.errorType as keyof typeof viVN.Errors],
                NotificationMessageType.Error
            );
            console.log('return true 2;');
            return true;
        });
    };

    render() {
        const value = this.state.value
        return (
            <div className="container-fluid p-0 layer-edited-container" >
                <div className="d-flex flex-column m-0 row flex-lg-row">
                    <div className="col-lg-4">
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={this.handleChange}
                            aria-label="Vertical tabs example"
                        >
                            <Tab label="Thông tin layer" {...a11yProps(0)} />
                            <Tab label="Thay đổi cài đặt" {...a11yProps(1)} />
                            <Tab label="Đổi nguồn dữ liệu" {...a11yProps(2)} />
                            <Tab label="Chỉnh sửa style layer" {...a11yProps(3)} />
                            <Tab label="Thay đổi cài đặt hiển thị" {...a11yProps(4)} />
                            <Tab label="Thay đổi cài đặt lọc" {...a11yProps(5)} />
                            <Tab label="Thay đổi ảnh ghi chú" {...a11yProps(6)} />
                        </Tabs>
                    </div>
                    <div className="col-lg-8">
                        <div className="limited-big-container">
                            <TabPanel value={value} index={0}>
                                <GeneralInfomationView data={this.state.layerData} />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <EditLayerSettingView
                                    data={this.state.layerData}
                                    setData={(data: LayerSettingsModels.LayerSettingsModel) => this.setState({ layerData: data })}
                                    isLayerRela={this.state.isLayerRela}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <ChangeLayerDataSource 
                                    data={this.state.layerData}
                                    dataSource={this.props.layerData}
                                    setData={(data: LayerSettingsModels.LayerSettingsModel) => this.setState({ layerData: data })}
                                    jumpToLayerStyleTab={()=>{this.setState({value: 3})}}
                                    setValueTiffFile={(val: any)=> this.setState({valueTiffFile: val})}
                                    isJumpFromStyle={this.state.isJumpFromStyle}
                                    setIsJumpFromStyle={this.setIsJumpFromStyle}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <LayerStyle
                                    styleName={this.state.styleName}
                                    setStyleName={(val: string) => this.setState({ styleName: val})}
                                    setIsEditStyle={() => {this.setState({isEditStyle: true})}}
                                    jumpToChangeSourceTab={()=>{this.setState({value: 2})}}
                                    setIsJumpFromStyle={this.setIsJumpFromStyle}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                <LayerInfomationViewEdit
                                    table={this.state.layerData.table}
                                    viewDetail={this.state.viewDetail}
                                    tooltip={this.state.tooltip}
                                    popup={this.state.popup}
                                    dataSource={this.state.dataSource}
                                    setTooltip={(data: ConfigModels.ObjectOfArray[]) => this.setState({ tooltip: data })}
                                    setViewDetail={(data: ConfigModels.ObjectOfArray[]) => this.setState({ viewDetail: data })}
                                    setPopup={(data: ConfigModels.ObjectOfArray[]) => this.setState({ popup: data })}
                                    setDataSource={(data: ConfigModels.SortDataSourceObject) => this.setState({ dataSource: data })} />
                            </TabPanel>
                            <TabPanel value={value} index={5}>
                                <SettingLayerFilterForEditView
                                    sortWidth={this.state.sortWidth}
                                    inputSetting={this.state.inputSetting}
                                    outputSetting={this.state.outputSetting}
                                    setSortWidth={(data: string) => this.setState({ sortWidth: data })}
                                    setInputSetting={(data: ConfigModels.ObjectOfArray[]) => this.setState({ inputSetting: data })}
                                    setOutputSetting={(data: ConfigModels.ObjectOfArray[]) => this.setState({ outputSetting: data })}
                                    dataSource={this.state.dataSource}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={6}>
                                <SettingDescription data={this.state.layerData} setData={(data: LayerSettingsModels.LayerSettingsModel) => this.setState({ layerData: data })} ></SettingDescription>
                            </TabPanel>
                        </div>
                    </div>
                    <div className="col-lg-6 text-danger">
                        (*): Mọi thay đổi chỉ được áp dụng khi ấn lưu lại
                    </div>
                    <div className="col-lg-6 text-right mb-3">
                        <Button variant="contained" className="mr-3" onClick={() => this.props.closeModal()}>Đóng</Button>
                        <Button variant="contained" color="primary" onClick={() => this.handleSave()}>Lưu lại</Button>
                    </div>
                </div>
            </div>
        )
    }
}